import { Theme } from "protomaps-themes-base";

export const GRAYSCALE: Theme = {
  background: "#a3a3a3",
  earth: "#cccccc",
  park_a: "#c2c2c2",
  park_b: "#c2c2c2",
  hospital: "#d0d0d0",
  industrial: "#c6c6c6",
  school: "#d0d0d0",
  wood_a: "#c2c2c2",
  wood_b: "#c2c2c2",
  pedestrian: "#c4c4c4",
  scrub_a: "#c2c2c2",
  scrub_b: "#c2c2c2",
  glacier: "#d2d2d2",
  sand: "#d2d2d2",
  beach: "#d2d2d2",
  aerodrome: "#c9c9c9",
  runway: "#f5f5f5",
  water: "#a3a3a3",
  zoo: "#c7c7c7",
  military: "#bfbfbf",

  tunnel_other_casing: "#b8b8b8",
  tunnel_minor_casing: "#b8b8b8",
  tunnel_link_casing: "#b8b8b8",
  tunnel_major_casing: "#b8b8b8",
  tunnel_highway_casing: "#b8b8b8",
  tunnel_other: "#d6d6d6",
  tunnel_minor: "#d6d6d6",
  tunnel_link: "#d6d6d6",
  tunnel_major: "#d6d6d6",
  tunnel_highway: "#d6d6d6",

  pier: "#b8b8b8",
  buildings: "#e0e0e0",

  minor_service_casing: "#cccccc",
  minor_casing: "#cccccc",
  link_casing: "#cccccc",
  major_casing_late: "#cccccc",
  highway_casing_late: "#cccccc",
  other: "#e0e0e0",
  minor_service: "#e0e0e0",
  minor_a: "#ebebeb",
  minor_b: "#e0e0e0",
  link: "#ebebeb",
  major_casing_early: "#cccccc",
  major: "#ebebeb",
  highway_casing_early: "#cccccc",
  highway: "#ebebeb",

  railway: "#f5f5f5",
  boundaries: "#5c5c5c",
  waterway_label: "#7a7a7a",

  bridges_other_casing: "#cccccc",
  bridges_minor_casing: "#cccccc",
  bridges_link_casing: "#cccccc",
  bridges_major_casing: "#cccccc",
  bridges_highway_casing: "#cccccc",
  bridges_other: "#e0e0e0",
  bridges_minor: "#e0e0e0",
  bridges_link: "#ebebeb",
  bridges_major: "#ebebeb",
  bridges_highway: "#ebebeb",

  roads_label_minor: "#999999",
  roads_label_minor_halo: "#e0e0e0",
  roads_label_major: "#8f8f8f",
  roads_label_major_halo: "#ebebeb",
  ocean_label: "#7a7a7a",
  peak_label: "#5c5c5c",
  subplace_label: "#7a7a7a",
  subplace_label_halo: "#cccccc",
  city_label: "#474747",
  city_label_halo: "#cccccc",
  state_label: "#999999",
  state_label_halo: "#cccccc",
  country_label: "#858585",
};

export const LIGHT: Theme = {
  background: "#cccccc",
  earth: "#e2dfda",
  park_a: "#cfddd5",
  park_b: "#9cd3b4",
  hospital: "#e4dad9",
  industrial: "#d1dde1",
  school: "#e4ded7",
  wood_a: "#d0ded0",
  wood_b: "#a0d9a0",
  pedestrian: "#e3e0d4",
  scrub_a: "#cedcd7",
  scrub_b: "#99d2bb",
  glacier: "#e7e7e7",
  sand: "#e2e0d7",
  beach: "#e8e4d0",
  aerodrome: "#dadbdf",
  runway: "#e9e9ed",
  water: "#80deea",
  zoo: "#c6dcdc",
  military: "#dcdcdc",

  tunnel_other_casing: "#e0e0e0",
  tunnel_minor_casing: "#e0e0e0",
  tunnel_link_casing: "#e0e0e0",
  tunnel_major_casing: "#e0e0e0",
  tunnel_highway_casing: "#e0e0e0",
  tunnel_other: "#d5d5d5",
  tunnel_minor: "#d5d5d5",
  tunnel_link: "#d5d5d5",
  tunnel_major: "#d5d5d5",
  tunnel_highway: "#d5d5d5",

  pier: "#e0e0e0",
  buildings: "#cccccc",

  minor_service_casing: "#e0e0e0",
  minor_casing: "#e0e0e0",
  link_casing: "#e0e0e0",
  major_casing_late: "#e0e0e0",
  highway_casing_late: "#e0e0e0",
  other: "#ebebeb",
  minor_service: "#ebebeb",
  minor_a: "#ebebeb",
  minor_b: "#ffffff",
  link: "#ffffff",
  major_casing_early: "#e0e0e0",
  major: "#ffffff",
  highway_casing_early: "#e0e0e0",
  highway: "#ffffff",

  railway: "#a7b1b3",
  boundaries: "#adadad",
  waterway_label: "#ffffff",

  bridges_other_casing: "#e0e0e0",
  bridges_minor_casing: "#e0e0e0",
  bridges_link_casing: "#e0e0e0",
  bridges_major_casing: "#e0e0e0",
  bridges_highway_casing: "#e0e0e0",
  bridges_other: "#ebebeb",
  bridges_minor: "#ffffff",
  bridges_link: "#ffffff",
  bridges_major: "#f5f5f5",
  bridges_highway: "#ffffff",

  roads_label_minor: "#91888b",
  roads_label_minor_halo: "#ffffff",
  roads_label_major: "#938a8d",
  roads_label_major_halo: "#ffffff",
  ocean_label: "#728dd4",
  peak_label: "#7e9aa0",
  subplace_label: "#8f8f8f",
  subplace_label_halo: "#e0e0e0",
  city_label: "#5c5c5c",
  city_label_halo: "#e0e0e0",
  state_label: "#b3b3b3",
  state_label_halo: "#e0e0e0",
  country_label: "#a3a3a3",

  pois: {
    blue: "#1A8CBD",
    green: "#20834D",
    lapis: "#315BCF",
    pink: "#EF56BA",
    red: "#F2567A",
    slategray: "#6A5B8F",
    tangerine: "#CB6704",
    turquoise: "#00C3D4",
  },

  landcover: {
    grassland: "rgba(210, 239, 207, 1)",
    barren: "rgba(255, 243, 215, 1)",
    urban_area: "rgba(230, 230, 230, 1)",
    farmland: "rgba(216, 239, 210, 1)",
    glacier: "rgba(255, 255, 255, 1)",
    scrub: "rgba(234, 239, 210, 1)",
    forest: "rgba(196, 231, 210, 1)",
  },
};

export const DARK: Theme = {
  background: "#34373d",
  earth: "#1f1f1f",
  park_a: "#1c2421",
  park_b: "#192a24",
  hospital: "#252424",
  industrial: "#222222",
  school: "#262323",
  wood_a: "#202121",
  wood_b: "#202121",
  pedestrian: "#1e1e1e",
  scrub_a: "#222323",
  scrub_b: "#222323",
  glacier: "#1c1c1c",
  sand: "#212123",
  beach: "#28282a",
  aerodrome: "#1e1e1e",
  runway: "#333333",
  water: "#31353f",
  zoo: "#222323",
  military: "#242323",

  tunnel_other_casing: "#141414",
  tunnel_minor_casing: "#141414",
  tunnel_link_casing: "#141414",
  tunnel_major_casing: "#141414",
  tunnel_highway_casing: "#141414",
  tunnel_other: "#292929",
  tunnel_minor: "#292929",
  tunnel_link: "#292929",
  tunnel_major: "#292929",
  tunnel_highway: "#292929",

  pier: "#333333",
  buildings: "#111111",

  minor_service_casing: "#1f1f1f",
  minor_casing: "#1f1f1f",
  link_casing: "#1f1f1f",
  major_casing_late: "#1f1f1f",
  highway_casing_late: "#1f1f1f",
  other: "#333333",
  minor_service: "#333333",
  minor_a: "#3d3d3d",
  minor_b: "#333333",
  link: "#3d3d3d",
  major_casing_early: "#1f1f1f",
  major: "#3d3d3d",
  highway_casing_early: "#1f1f1f",
  highway: "#474747",

  railway: "#000000",
  boundaries: "#5b6374",
  waterway_label: "#717784",

  bridges_other_casing: "#2b2b2b",
  bridges_minor_casing: "#1f1f1f",
  bridges_link_casing: "#1f1f1f",
  bridges_major_casing: "#1f1f1f",
  bridges_highway_casing: "#1f1f1f",
  bridges_other: "#333333",
  bridges_minor: "#333333",
  bridges_link: "#3d3d3d",
  bridges_major: "#3d3d3d",
  bridges_highway: "#474747",

  roads_label_minor: "#525252",
  roads_label_minor_halo: "#1f1f1f",
  roads_label_major: "#666666",
  roads_label_major_halo: "#1f1f1f",
  ocean_label: "#717784",
  peak_label: "#898080",
  subplace_label: "#525252",
  subplace_label_halo: "#1f1f1f",
  city_label: "#7a7a7a",
  city_label_halo: "#212121",
  state_label: "#3d3d3d",
  state_label_halo: "#1f1f1f",
  country_label: "#5c5c5c",

  pois: {
    blue: "#4299BB",
    green: "#30C573",
    lapis: "#2B5CEA",
    pink: "#EF56BA",
    red: "#F2567A",
    slategray: "#93939F",
    tangerine: "#F19B6E",
    turquoise: "#00C3D4",
  },

  landcover: {
    grassland: "rgba(30, 41, 31, 1)",
    barren: "rgba(38, 38, 36, 1)",
    urban_area: "rgba(28, 28, 28, 1)",
    farmland: "rgba(31, 36, 32, 1)",
    glacier: "rgba(43, 43, 43, 1)",
    scrub: "rgba(34, 36, 30, 1)",
    forest: "rgba(28, 41, 37, 1)",
  },
};

export const RAINFORSET: Theme = {
  background: "rgba(231, 240, 221, 1)",
  earth: "rgba(230, 203, 177, 1)",
  park_a: "#1e4f4a",
  park_b: "#1e4f4a",
  hospital: "rgba(238, 112, 200, 1)",
  industrial: "rgba(81, 87, 91, 1)",
  school: "rgba(239, 111, 68, 1)",
  wood_a: "#3a8247",
  wood_b: "#3a8247",
  pedestrian: "rgba(246, 206, 192, 1)",
  scrub_a: "#5d501a",
  scrub_b: "#5d501a",
  glacier: "rgba(255, 255, 255, 1)",
  sand: "rgba(243, 201, 77, 1)",
  beach: "rgba(249, 211, 184, 1)",
  aerodrome: "rgba(183, 129, 177, 1)",
  runway: "rgba(10, 9, 11, 1)",
  water: "rgba(87, 150, 239, 1)",
  zoo: "rgba(40, 99, 67, 1)",
  military: "rgba(36, 34, 69, 1)",

  tunnel_other_casing: "rgba(230, 203, 177, 1)",
  tunnel_minor_casing: "rgba(230, 203, 177, 1)",
  tunnel_link_casing: "rgba(230, 203, 177, 1)",
  tunnel_major_casing: "rgba(230, 203, 177, 1)",
  tunnel_highway_casing: "rgba(230, 203, 177, 1)",
  tunnel_other: "rgba(27, 5, 16, 1)",
  tunnel_minor: "rgba(27, 5, 16, 1)",
  tunnel_link: "rgba(27, 5, 16, 1)",
  tunnel_major: "rgba(27, 5, 16, 1)",
  tunnel_highway: "rgba(27, 5, 16, 1)",

  pier: "rgba(104, 211, 253, 1)",
  buildings: "rgba(188, 109, 41, 1)",

  minor_service_casing: "rgba(230, 203, 177, 1)",
  minor_casing: "rgba(230, 203, 177, 1)",
  link_casing: "rgba(230, 203, 177, 1)",
  major_casing_late: "rgba(230, 203, 177, 1)",
  highway_casing_late: "rgba(230, 203, 177, 1)",
  other: "rgba(27, 5, 16, 1)",
  minor_service: "rgba(27, 5, 16, 1)",
  minor_a: "#1b0510",
  minor_b: "#1b0510",
  link: "rgba(27, 5, 16, 1)",
  major_casing_early: "rgba(230, 203, 177, 1)",
  major: "rgba(27, 5, 16, 1)",
  highway_casing_early: "rgba(230, 203, 177, 1)",
  highway: "rgba(27, 5, 16, 1)",

  railway: "rgba(21, 19, 20, 1)",
  boundaries: "rgba(206, 206, 206, 1)",
  waterway_label: "rgba(198, 228, 255, 1)",

  bridges_other_casing: "rgba(231, 240, 221, 1)",
  bridges_minor_casing: "rgba(231, 240, 221, 1)",
  bridges_link_casing: "rgba(231, 240, 221, 1)",
  bridges_major_casing: "rgba(231, 240, 221, 1)",
  bridges_highway_casing: "rgba(231, 240, 221, 1)",
  bridges_other: "rgba(27, 5, 16, 1)",
  bridges_minor: "rgba(27, 5, 16, 1)",
  bridges_link: "rgba(27, 5, 16, 1)",
  bridges_major: "rgba(27, 5, 16, 1)",
  bridges_highway: "rgba(27, 5, 16, 1)",

  roads_label_minor: "rgba(62, 31, 17, 1)",
  roads_label_minor_halo: "rgba(230, 203, 177, 1)",
  roads_label_major: "rgba(62, 31, 17, 1)",
  roads_label_major_halo: "rgba(230, 203, 177, 1)",
  ocean_label: "rgba(198, 228, 255, 1)",
  peak_label: "rgba(242, 242, 242, 1)",
  subplace_label: "rgba(255, 255, 255, 1)",
  subplace_label_halo: "rgba(0,0,0,0)",
  city_label: "rgba(255, 255, 255, 1)",
  city_label_halo: "rgba(0,0,0,0)",
  state_label: "rgba(255, 255, 255, 1)",
  state_label_halo: "rgba(0,0,0,0)",
  country_label: "rgba(255, 255, 255, 1)",

  landcover: {
    grassland: "rgba(147, 163, 53, 1)",
    barren: "rgba(204, 183, 62, 1)",
    urban_area: "rgba(182, 38, 25, 1)",
    farmland: "rgba(57, 130, 75, 1)",
    glacier: "rgba(255, 255, 255, 1)",
    scrub: "rgba(93, 80, 26, 1)",
    forest: "rgba(29, 75, 76, 1)",
  },
};

export const BIO: Theme = {
  background: "#dddddd",
  earth: "#ededed",
  park_a: "#bfc99c",
  park_b: "#bfc99c",
  hospital: "#ffeae8",
  industrial: "#f8ffed",
  school: "#f2fef9",
  wood_a: "#bfc99c",
  wood_b: "#bfc99c",
  pedestrian: "#eef0f0",
  scrub_a: "#bfc99c",
  scrub_b: "#bfc99c",
  glacier: "#ffffff",
  sand: "#ebe7da",
  beach: "#ebe7da",
  aerodrome: "#dbe7e7",
  runway: "#d1d9d9",
  water: "#84b7cf",
  zoo: "#ebe6ed",
  military: "#ebe6ed",

  tunnel_other_casing: "#ffffff",
  tunnel_minor_casing: "#e2e2e2",
  tunnel_link_casing: "#e2e2e2",
  tunnel_major_casing: "#e3cfd3",
  tunnel_highway_casing: "#ebcea2",
  tunnel_other: "#f7f7f7",
  tunnel_minor: "#ebebeb",
  tunnel_link: "#ebebeb",
  tunnel_major: "#ebebeb",
  tunnel_highway: "#ebebeb",

  pier: "#ededed",
  buildings: "#cbcece",

  minor_service_casing: "#e2e2e2",
  minor_casing: "#e2e2e2",
  link_casing: "#e2e2e2",
  major_casing_late: "#e3cfd3",
  highway_casing_late: "#ebcea2",
  other: "#ffffff",
  minor_service: "#ffffff",
  minor_a: "#fff2bb",
  minor_b: "#fff2bb",
  link: "#fff2bb",
  major_casing_early: "#e3cfd3",
  major: "#ffdf59",
  highway_casing_early: "#ebcea2",
  highway: "#e9ac77",

  railway: "#b3bcc9",
  boundaries: "#5c4a6b",
  waterway_label: "#a4cae1",

  bridges_other_casing: "#ffffff",
  bridges_minor_casing: "#e2e2e2",
  bridges_link_casing: "#e1e1e1",
  bridges_major_casing: "#e3cfd3",
  bridges_highway_casing: "#ebcea2",
  bridges_other: "#ffffff",
  bridges_minor: "#ffffff",
  bridges_link: "#e1e1e1",
  bridges_major: "#ffffff",
  bridges_highway: "#fefffc",

  roads_label_minor: "#91888b",
  roads_label_minor_halo: "#ffffff",
  roads_label_major: "#91888b",
  roads_label_major_halo: "#ffffff",
  ocean_label: "#ffffff",
  peak_label: "#61bb5b",
  subplace_label: "#757d91",
  subplace_label_halo: "#ffffff",
  city_label: "#3c3c3c",
  city_label_halo: "#ffffff",
  state_label: "#777777",
  state_label_halo: "#ffffff",
  country_label: "#9590aa",
};

export const SEAFOAM: Theme = {
  background: "rgba(231, 240, 221, 1)",
  earth: "rgba(231, 240, 221, 1)",
  park_a: "#8ad3d4",
  park_b: "#8ad3d4",
  hospital: "rgba(253, 160, 179, 1)",
  industrial: "rgba(191, 189, 186, 1)",
  school: "rgba(250, 220, 166, 1)",
  wood_a: "#94ccc3",
  wood_b: "#94ccc3",
  pedestrian: "rgba(198, 220, 216, 1)",
  scrub_a: "#a4c6a2",
  scrub_b: "#a4c6a2",
  glacier: "rgba(239, 240, 231, 1)",
  sand: "rgba(222, 218, 189, 1)",
  beach: "rgba(236, 251, 218, 1)",
  aerodrome: "rgba(218, 211, 208, 1)",
  runway: "rgba(232, 250, 238, 1)",
  water: "rgba(173, 230, 221, 1)",
  zoo: "rgba(143, 211, 167, 1)",
  military: "rgba(173, 188, 195, 1)",

  tunnel_other_casing: "rgba(231, 240, 221, 1)",
  tunnel_minor_casing: "rgba(231, 240, 221, 1)",
  tunnel_link_casing: "rgba(231, 240, 221, 1)",
  tunnel_major_casing: "rgba(231, 240, 221, 1)",
  tunnel_highway_casing: "rgba(231, 240, 221, 1)",
  tunnel_other: "rgba(255, 255, 255, 1)",
  tunnel_minor: "rgba(255, 255, 255, 1)",
  tunnel_link: "rgba(255, 255, 255, 1)",
  tunnel_major: "rgba(255, 255, 255, 1)",
  tunnel_highway: "rgba(255, 255, 255, 1)",

  pier: "rgba(137, 195, 217, 1)",
  buildings: "rgba(218, 222, 217, 1)",

  minor_service_casing: "rgba(231, 240, 221, 1)",
  minor_casing: "rgba(231, 240, 221, 1)",
  link_casing: "rgba(231, 240, 221, 1)",
  major_casing_late: "rgba(231, 240, 221, 1)",
  highway_casing_late: "rgba(231, 240, 221, 1)",
  other: "rgba(255, 255, 255, 1)",
  minor_service: "rgba(255, 255, 255, 1)",
  minor_a: "#ffffff",
  minor_b: "#ffffff",
  link: "rgba(255, 255, 255, 1)",
  major_casing_early: "rgba(231, 240, 221, 1)",
  major: "rgba(255, 255, 255, 1)",
  highway_casing_early: "rgba(231, 240, 221, 1)",
  highway: "rgba(255, 255, 255, 1)",

  railway: "rgba(255, 255, 255, 1)",
  boundaries: "rgba(136, 148, 136, 1)",
  waterway_label: "rgba(90, 145, 147, 1)",

  bridges_other_casing: "rgba(231, 240, 221, 1)",
  bridges_minor_casing: "rgba(231, 240, 221, 1)",
  bridges_link_casing: "rgba(231, 240, 221, 1)",
  bridges_major_casing: "rgba(231, 240, 221, 1)",
  bridges_highway_casing: "rgba(231, 240, 221, 1)",
  bridges_other: "rgba(255, 255, 255, 1)",
  bridges_minor: "#ffffff",
  bridges_link: "#ffffff",
  bridges_major: "rgba(255, 255, 255, 1)",
  bridges_highway: "#ffffff",

  roads_label_minor: "rgba(137, 149, 142, 1)",
  roads_label_minor_halo: "#ffffff",
  roads_label_major: "rgba(137, 149, 142, 1)",
  roads_label_major_halo: "#ffffff",
  ocean_label: "rgba(91, 179, 181, 1)",
  peak_label: "rgba(95, 108, 135, 1)",
  subplace_label: "rgba(131, 130, 130, 1)",
  subplace_label_halo: "rgba(0,0,0,0)",
  city_label: "rgba(84, 92, 94, 1)",
  city_label_halo: "rgba(0,0,0,0)",
  state_label: "rgba(118, 118, 118, 1)",
  state_label_halo: "rgba(0,0,0,0)",
  country_label: "rgba(130, 145, 155, 1)",

  regular: "Jost Regular",
  bold: "Jost Medium",
  italic: "Jost Italic",

  landcover: {
    grassland: "rgba(143, 201, 173, 1)",
    barren: "rgba(232, 214, 183, 1)",
    urban_area: "rgba(224, 232, 216, 1)",
    farmland: "rgba(165, 213, 166, 1)",
    glacier: "rgba(239, 240, 231, 1)",
    scrub: "rgba(176, 205, 174, 1)",
    forest: "rgba(149, 207, 194, 1)",
  },
};

export const IRIS: Theme = {
  background: "rgba(255, 255, 255, 1)",
  earth: "rgba(226, 222, 235, 1)",
  park_a: "#51cbdd",
  park_b: "#51cbdd",
  hospital: "rgba(242, 175, 195, 1)",
  industrial: "rgba(183, 196, 240, 1)",
  school: "rgba(242, 174, 78, 1)",
  wood_a: "#52c5a7",
  wood_b: "#52c5a7",
  pedestrian: "rgba(205, 198, 218, 1)",
  scrub_a: "#eccb6a",
  scrub_b: "#eccb6a",
  glacier: "rgba(238, 230, 240, 1)",
  sand: "rgba(240, 200, 73, 1)",
  beach: "rgba(244, 219, 118, 1)",
  aerodrome: "rgba(221, 222, 187, 1)",
  runway: "rgba(255, 255, 255, 1)",
  water: "rgba(138, 222, 255, 1)",
  zoo: "rgba(145, 214, 197, 1)",
  military: "rgba(145, 214, 197, 1)",

  tunnel_other_casing: "rgba(226, 222, 235, 1)",
  tunnel_minor_casing: "rgba(226, 222, 235, 1)",
  tunnel_link_casing: "rgba(226, 222, 235, 1)",
  tunnel_major_casing: "rgba(226, 222, 235, 1)",
  tunnel_highway_casing: "rgba(226, 222, 235, 1)",
  tunnel_other: "rgba(255, 255, 255, 1)",
  tunnel_minor: "rgba(255, 255, 255, 1)",
  tunnel_link: "rgba(255, 255, 255, 1)",
  tunnel_major: "rgba(255, 255, 255, 1)",
  tunnel_highway: "rgba(255, 255, 255, 1)",

  pier: "rgba(109, 177, 236, 1)",
  buildings: "rgba(204, 201, 220, 1)",

  minor_service_casing: "rgba(226, 222, 235, 1)",
  minor_casing: "rgba(226, 222, 235, 1)",
  link_casing: "rgba(226, 222, 235, 1)",
  major_casing_late: "rgba(226, 222, 235, 1)",
  highway_casing_late: "rgba(226, 222, 235, 1)",
  other: "rgba(255, 255, 255, 1)",
  minor_service: "rgba(255, 255, 255, 1)",
  minor_a: "#ffffff",
  minor_b: "#ffffff",
  link: "rgba(255, 255, 255, 1)",
  major_casing_early: "rgba(226, 222, 235, 1)",
  major: "rgba(255, 255, 255, 1)",
  highway_casing_early: "rgba(226, 222, 235, 1)",
  highway: "rgba(255, 255, 255, 1)",

  railway: "rgba(252, 249, 248, 1)",
  boundaries: "rgba(16, 24, 101, 1)",
  waterway_label: "",

  bridges_other_casing: "rgba(226, 222, 235, 1)",
  bridges_minor_casing: "rgba(226, 222, 235, 1)",
  bridges_link_casing: "rgba(226, 222, 235, 1)",
  bridges_major_casing: "rgba(226, 222, 235, 1)",
  bridges_highway_casing: "rgba(226, 222, 235, 1)",
  bridges_other: "rgba(255, 255, 255, 1)",
  bridges_minor: "rgba(255, 255, 255, 1)",
  bridges_link: "rgba(255, 255, 255, 1)",
  bridges_major: "rgba(255, 255, 255, 1)",
  bridges_highway: "rgba(255, 255, 255, 1)",

  roads_label_minor: "rgba(55, 22, 65, 1)",
  roads_label_minor_halo: "rgba(255, 255, 255, 1)",
  roads_label_major: "rgba(55, 22, 65, 1)",
  roads_label_major_halo: "#ffffff",
  ocean_label: "rgba(44, 107, 216, 1)",
  peak_label: "#61bb5b",
  subplace_label: "rgba(130, 109, 145, 1)",
  subplace_label_halo: "rgba(0,0,0,0)",
  city_label: "rgba(3, 23, 74, 1)",
  city_label_halo: "rgba(0,0,0,0)",
  state_label: "rgba(112, 78, 125, 1)",
  state_label_halo: "rgba(0,0,0,0)",
  country_label: "rgba(94, 96, 126, 1)",

  landcover: {
    grassland: "rgba(56, 224, 140, 1)",
    barren: "rgba(224, 195, 133, 1)",
    urban_area: "rgba(232, 190, 247, 1)",
    farmland: "rgba(44, 203, 158, 1)",
    glacier: "rgba(238, 230, 240, 1)",
    scrub: "rgba(122, 205, 111, 1)",
    forest: "rgba(57, 205, 151, 1)",
  },
};

//import chroma from "chroma-js";
import { FillLayerSpecification, SpriteSpecification, StyleSpecification } from "maplibre-gl";
import { layersWithCustomTheme } from "protomaps-themes-base";
import { Theme } from "protomaps-themes-base";

export default ({
  url,
  lang,
  glyphs,
  maxzoom,
  attribution,
  theme,
  sprite,
  buildings,
}: {
  url: string;
  lang: string;
  glyphs?: string;
  maxzoom: number;
  attribution?: string;
  theme: Theme;
  sprite?: SpriteSpecification;
  buildings?: {
    three?: boolean,
    detour?: boolean,
  }
}): StyleSpecification => {
  const source = "protomaps";

  const layers = layersWithCustomTheme(source, theme, lang);
  /*layers.forEach(layer => {
    if (layer.id == 'earth' || layer.id == 'water') {
      (layer as FillLayerSpecification).filter = ["==", ["geometry-type"], "Polygon"]
    }
  })*/
  if (buildings) { 
    const buildingsPos = layers.findIndex(layer => layer.id == 'buildings')
    if (buildings.detour) {
      // rajout d'un détour autour des buildings
      layers.splice(buildingsPos, 0, {
        id: "buildings-detour",
        type: "line",
        source: source,
        "source-layer": "buildings",
        paint: {
          "line-color": theme.background,
          "line-width": 1,
        },
      });
    }
    if (buildings.three) {
      layers.splice(buildingsPos, 0, {
        id: "buildings-3d",
        source: source,
        "source-layer": "buildings",
        type: "fill-extrusion",
        minzoom: 15,
        paint: {
          "fill-extrusion-color": [
            "interpolate",
            ["linear"],
            ["coalesce", ["get", "height"], 0],
            0,
            theme.buildings,
            200,
            theme.buildings, // chroma(customTheme.buildings).saturate(0.5).hex(),
            400,
            theme.buildings, //chroma(customTheme.buildings).saturate(1).hex(),
          ],
          "fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 16, ["get", "height"]],
          "fill-extrusion-base": ["case", [">=", ["get", "zoom"], 16], ["get", "min_height"], 0],
          "fill-extrusion-opacity": 0.6,
        },
      });
    }
  }

  return {
    version: 8,
    glyphs,
    sprite,
    sources: {
      protomaps: {
        type: "vector",
        maxzoom,
        attribution: attribution ??
          '<a href="https://github.com/protomaps/basemaps">Protomaps</a> © <a href="https://openstreetmap.org">OpenStreetMap</a>',
        ...(url.indexOf("pmtiles") > -1
          ? {
              url: "pmtiles://" + url,
            }
          : {
              tiles: [url],
            }),
      },
    },
    layers: layers,
  };
};

import { StyleSpecification } from "maplibre-gl";

export default ({
  url,
  glyphs,
  attribution,
  maxzoom,
  tileSize,
}: {
  url: string;
  glyphs: string;
  attribution: string;
  maxzoom?: number;
  tileSize?: number;
}): StyleSpecification => {
  return {
    version: 8,
    glyphs,
    sources: {
      "raster-tiles": {
        type: "raster",
        tiles: [url],
        tileSize: tileSize || 256,
        attribution: attribution,
      },
    },
    layers: [
      {
        id: "simple-tiles",
        type: "raster",
        source: "raster-tiles",
        minzoom: 0,
        maxzoom: maxzoom || 22,
      },
    ],
  };
};

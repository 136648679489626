import {
  ActionIcon,
  AppShell,
  Burger,
  Button,
  Container,
  Group,
  MantineProvider,
  Menu,
  Table,
  Text,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAnalyze,
  IconArrowsLeftRight,
  IconCube3dSphere,
  IconMessageCircle,
  IconMoonStars,
  IconPhoto,
  IconSearch,
  IconSettings,
  IconSun,
  IconTrash,
} from "@tabler/icons-react";
import { Map as MapGL, NavigationControl } from "@vis.gl/react-maplibre";
import { StyleSpecification, addProtocol, removeProtocol, setRTLTextPlugin } from "maplibre-gl";
import { Protocol } from "pmtiles";
import { JSX, render } from "preact";
import { useEffect, useState } from "preact/hooks";
import {
  BIO,
  DARK,
  GlobeControl,
  IRIS,
  LIGHT,
  RAINFORSET,
  SEAFOAM,
  StyleSwitcherControl,
  rasterMapStyle,
  vectorMapStyle,
} from "../../utils/maplibre";
import "../../utils/maplibre/switcher.css";
import "./app.css";

setRTLTextPlugin("https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.js", true);

const ColorSchemeButton = () => {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme("dark", { getInitialValueInEffect: true });

  return (
    <ActionIcon
      variant="subtle"
      color={computedColorScheme == "dark" ? "yellow" : "blue"}
      onClick={() => setColorScheme(computedColorScheme === "dark" ? "light" : "dark")}
      title="Toggle color scheme"
    >
      {computedColorScheme == "dark" ? <IconSun size="1.0rem" /> : <IconMoonStars size="1.0rem" />}
    </ActionIcon>
  );
};

const elements = [
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
  { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
  { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
  { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
  { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
  { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
];

export function TestMenu() {
  return (
    <Menu shadow="md" position="bottom-start">
      <Menu.Target>
        <Button variant="subtle" color="gray" leftSection={<IconAnalyze className="rootshell-menu-button" />}>
          Menu
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Application</Menu.Label>
        <Menu.Item leftSection={<IconSettings className="rootshell-submenu-button" />}>Settings</Menu.Item>
        <Menu.Item leftSection={<IconMessageCircle className="rootshell-submenu-button" />}>Messages</Menu.Item>
        <Menu.Item leftSection={<IconPhoto className="rootshell-submenu-button" />}>Gallery</Menu.Item>
        <Menu.Item
          leftSection={<IconSearch className="rootshell-submenu-button" />}
          rightSection={
            <Text size="xs" c="dimmed">
              ⌘K
            </Text>
          }
        >
          Search
        </Menu.Item>

        <Menu.Divider />

        <Menu.Label>Danger zone</Menu.Label>
        <Menu.Item leftSection={<IconArrowsLeftRight className="rootshell-submenu-button" />}>Transfer my data</Menu.Item>
        <Menu.Item color="red" leftSection={<IconTrash className="rootshell-submenu-button" />}>
          Delete my account
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export function RootShell({ children, menus }: { children: JSX.Element; menus: JSX.Element[] }) {
  const [opened, { toggle }] = useDisclosure();

  const toggleIfMenu = (e: MouseEvent) => {
    if (e.defaultPrevented) {
      toggle();
    }
  };

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: "sm", collapsed: { desktop: true, mobile: !opened } }}
      padding="0"
      styles={{
        main: {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Group justify="space-between" style={{ flex: 1 }}>
            <Button
              mr="auto"
              variant="subtle"
              leftSection={<IconCube3dSphere stroke={2} />}
              styles={{
                label: {
                  fontSize: 20,
                },
              }}
            >
              YOPLA
            </Button>

            <Group ml="xl" gap={0} visibleFrom="sm" onClick={toggleIfMenu}>
              {menus}
            </Group>
            <ColorSchemeButton></ColorSchemeButton>
          </Group>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar py="md" px={4} style={{ alignItems: "start" }} onClick={toggleIfMenu}>
        {menus}
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}

export function App() {
  const computedColorScheme = useComputedColorScheme("dark", { getInitialValueInEffect: true });
  const [selectedMenu, setSelectedMenu] = useState<string>("home");
  const onMenuClick = (e: MouseEvent, menu: string) => {
    setSelectedMenu(menu);
    e.preventDefault();
  };
  const [mapStyles, setMapStyles] = useState<{
    [key: string]: {
      className: string;
      style?: StyleSpecification;
    };
  }>();

  useEffect(() => {
    setMapStyles({
      STREETS: {
        className: "switcher-streets",
        style: vectorMapStyle({
          url: "https://api.protomaps.com/tiles/v4/{z}/{x}/{y}.mvt?key=2f5c408cb6488878",
          //url: "https://tiles.shagr.at/world11.pmtiles",
          glyphs: "https://tiles.shagr.at/basemaps-assets/fonts/{fontstack}/{range}.pbf",
          lang: "fr",
          maxzoom: 15 /* 15 pour la map complète */,
          theme: computedColorScheme == "dark" ? DARK : BIO,
          sprite: `https://tiles.shagr.at/basemaps-assets/sprites/v4/${computedColorScheme == "dark" ? "dark" : "light"}`,
          buildings: {
            three: true,
            detour: true,
          },
        }),
      },
      SATELLITE: {
        className: "switcher-hybrid",
        style: rasterMapStyle({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          glyphs: "https://tiles.shagr.at/basemaps-assets/fonts/{fontstack}/{range}.pbf",
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }),
      },
    });
  }, [computedColorScheme]);

  useEffect(() => {
    const protocol = new Protocol();
    addProtocol("pmtiles", protocol.tile);
    return () => {
      removeProtocol("pmtiles");
    };
  }, []);

  return (
    <RootShell
      menus={[
        <Button key="home" variant="subtle" color="gray" onClick={(e: MouseEvent) => onMenuClick(e, "home")}>
          Home
        </Button>,
        <Button key="map" variant="subtle" color="gray" onClick={(e: MouseEvent) => onMenuClick(e, "map")}>
          Map
        </Button>,
        <TestMenu key="test"></TestMenu>,
      ]}
    >
      {selectedMenu == "home" ? (
        <Container p="md" w="100%" maw="100%">
          <Table stickyHeader stickyHeaderOffset={60}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Element position</Table.Th>
                <Table.Th>Element name</Table.Th>
                <Table.Th>Symbol</Table.Th>
                <Table.Th>Atomic mass</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {elements.map((element) => (
                <Table.Tr key={element.name}>
                  <Table.Td>{element.position}</Table.Td>
                  <Table.Td>{element.name}</Table.Td>
                  <Table.Td>{element.symbol}</Table.Td>
                  <Table.Td>{element.mass}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
            <Table.Caption>Liste</Table.Caption>
          </Table>
        </Container>
      ) : mapStyles ? (
        <MapGL
          initialViewState={{
            longitude: -1.5518693,
            latitude: 47.252479,
            zoom: 15,
            pitch: 0,
            bearing: 0,
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          }}
          maxZoom={22}
          attributionControl={{ compact: true }}
          style={{ width: "100%", flex: 1, height: "inherit" }}
        >
          <NavigationControl visualizePitch={true} showCompass={true} position="top-right" />
          <GlobeControl position="top-right" />
          <StyleSwitcherControl position="bottom-left" expandDirection="right" initialStyle="STREETS" styles={mapStyles} />
        </MapGL>
      ) : (
        <div></div>
      )}
    </RootShell>
  );
}

render(
  <MantineProvider
    defaultColorScheme="auto"
    theme={{
      fontFamily: "Inter",
    }}
  >
    <App />
  </MantineProvider>,
  document.body,
);
